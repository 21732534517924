<template>
  <v-app class="my-application">
    <NavigationBar v-if="this.authenticated" />
    <v-main>
        <router-view />
    </v-main>
  </v-app>
</template>

<script>
import NavigationBar from "./components/NavigationBar";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    // navbar,
    NavigationBar,
  },

  data: () => ({
    //
  }),
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
    }),
  },
};
</script>
<style>
#app {
  background-color: #f1efef;
}
</style>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@300;500;700&display=swap");
$font-family: "Cairo", serif;

.my-application {

  .headline,
  [class*="display-"],
  [class*="text-"] {
    font-family: $font-family, sans-serif !important;
  }

  font-family: $font-family,
  sans-serif !important;
}
</style>