<template>
  <div>
    <v-navigation-drawer right v-model="drawer" app class="white" :width="300">
      <v-sheet height="64" color="primary">
        <div style="height: 100%" class="d-flex align-center justify-center">
          <p class="ma-0 ml-6 text-h6 white--text"></p>
        </div>
      </v-sheet>
      <v-divider></v-divider>
      <v-list nav>
        <div
          class="pt-4 px-2"
          v-for="[icon, text, link, isGroup, items] in links"
          :key="text"
        >
          <v-list-item v-if="!isGroup" link :to="link">
            <v-list-item-icon>
              <v-icon
                :color="
                  currentRouteName.includes(link) ? 'primary' : 'secondary'
                "
                v-text="icon"
              ></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group v-if="isGroup" :prepend-icon="icon">
            <v-list-item v-for="[text, link] in items" :key="link" :to="link">
              <v-list-item-content>
                <v-list-item-title v-text="text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app outlined color="primary" flat dark height="64">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <div class="d-flex flex-row justify-center align-center mx-0">
        <v-avatar size="35">
          <img :src="user.avatar_url" />
        </v-avatar>
        <p class="ma-0 mx-md-4 mx-2 white--text text-body-1">
          {{ user.email }}
          <v-menu :close-on-content-click="false" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon color="white" v-bind="attrs" v-on="on">
                <v-icon>mdi-triangle-small-down</v-icon>
              </v-btn>
            </template>
            <v-card width="275" min-width="200">
              <v-row justify="center" no-gutters>
                <v-col
                  cols="12"
                  class="d-flex justify-center align-center background px-3"
                >
                  <v-list-item two-line>
                    <v-list-item-avatar>
                      <v-img v-if="user.avatar_url" :src="user.avatar_url">
                      </v-img>
                      <p v-else class="ma-0 text-caption">No Image</p>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <p
                        class="ma-0 text-body-2 font-weight-bold primary--text"
                      >
                        {{ user.name }}
                      </p>
                      <p class="ma-0 text-body-1 primaryfont--text">
                        {{ user.email }}
                      </p>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" class="px-3">
                  <v-list dense nav>
                    <v-btn
                      depressed
                      plain
                      v-for="(item, i) in items"
                      :key="i"
                      @click="$router.push(item.link)"
                    >
                      <v-icon small v-text="item.icon"></v-icon>
                      <p class="ml-4 ma-0">{{ item.text }}</p>
                    </v-btn>
                  </v-list>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" class="px-3">
                  <v-list dense nav>
                    <v-list-item link @click="Logout">
                      <v-list-item-icon>
                        <v-icon>mdi-logout</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>تسجيل الخروج</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card>
          </v-menu>
        </p>
      </div>
    </v-app-bar>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import constants from "@/constants.js";

export default {
  data: () => ({
    drawer: null,
    constants: constants,
    selectedItem: 1,
    items: [
      { text: "حسابي الشخصي", icon: "mdi-account-outline", link: "/profile" },
    ],
    links: [
      ["mdi-account-group", "الاحصائيات", "/dashboard", false, []],
      ["mdi-account-group", "الاعدادات", "/settings", false, []],
      ["mdi-account-group", "الاوردرات", "/orders", false, []],
      ["mdi-doctor", "الموظفين", "/users", false, []],
      [
        "mdi-file-document-outline",
        "اعدادات النظام",
        "/settings",
        true,
        [
          ["الخامات", "/settings/main-category"],
          ["اليونتات", "/settings/sub-category"],
          ["الايتمات", "/settings/child-category"],
          ["الكوارتز", "/settings/quartz"],
        ],
      ],
    ],
  }),
  methods: {
    ...mapActions({
      LogoutAction: "auth/Logout",
    }),
    Logout() {
      this.LogoutAction().then(() => {
        this.$router.replace({ name: "login" });
      });
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.path;
    },
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
};
</script>
<style scoped>
.hover:hover {
  cursor: pointer;
}
</style>
