import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from './plugins/vuetify'
import axios from 'axios'
import checkUrl from "./checkUrl";
import dateConverter from "./dateConverter";
import phoneConverter from "./phoneConverter";
import Vue2Editor from "vue2-editor";
import imageCompression from "browser-image-compression";
import "./plugins/vuetify-money.js";
import money from 'v-money'
import VueTimepicker from 'vue2-timepicker'


Vue.config.productionTip = false
require('@/store/subscriber')
Vue.mixin(checkUrl);
Vue.mixin(dateConverter);
Vue.mixin(phoneConverter);
Vue.use(Vue2Editor);
Vue.use(imageCompression)
Vue.use(money)
Vue.use(VueTimepicker)

axios.defaults.baseURL = "https://backendevolve.dr-survey.com/api";
store.dispatch('auth/attempt', localStorage.getItem('token')).then(() => {
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
})
