import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
    beforeEnter: (to, from, next) => {
      if (store.getters["auth/authenticated"]) {
        return next({
          name: "Dashboard"
        });
      }
      next();
    }
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Dashboard.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/settings",
    name: "Settings",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Settings.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/orders",
    name: "Orders",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Orders.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/orders/create",
    name: "CreateOrder",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/CreateOrder.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/orders/:id/view",
    name: "ViewOrder",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/ViewOrder.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/orders/:id/update",
    name: "UpdateOrder",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/UpdateOrder.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/users",
    name: "Users",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Users.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/settings/main-category",
    name: "MainCategories",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/MainCategories.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/settings/sub-category",
    name: "SubCategories",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/SubCategories.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/settings/child-category",
    name: "ChildCategories",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/ChildCategories.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/settings/quartz",
    name: "Quartz",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Quartz.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
    {
    path: "/404",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "myads" */ "../views/NotFound.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  { path: "/:pathMatch(.*)*", redirect: "/404" },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
