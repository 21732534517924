export default {
  methods: {
    isValidHttpUrl(string) {
      let url;
      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }
      return url.protocol === "http:" || url.protocol === "https:";
    },
    linkToOtherWindow (url) {
      window.open(url, '_blank')
    }
  },
};
